// fonts
export const FONT_FAMILY = "Inter"
export const CONTENT_FONT_FAMILY = "Inter"

// colors
export const WHITE_COLOR = "#FFFFFF"
export const BLACK_COLOR = "#000000"
export const ACCENT_GREEN = "#98ECA6"
export const ACCENT_PORTICA = "#F5DA7D"
export const ACCENT_TEAL = "#98E5E0"
export const ACCENT_RED = "#FFABAB"
export const COLOR_DENIM = "#1565C0"
export const LIGHT_BLUE = "#54A5FF"
export const SPRING_GREEN = "#07F5AA"
export const LIGHT_GREY = "#EEEEEE"
export const CHARCOAL = "#444444"
export const DARK_GREY = "#AAAAAA"
export const PALE_BLUE = "#D0D7E2"
export const HAWKES_BLUE = "#DFEEFB"

// brand colors
export const APP_BRAND_COLOR = "#5860AF"
export const APP_BACKGROUND_COLOR = "#FFFFFF"
export const APP_HEADER_BACKGROUND_COLOR = "#FFF"
export const PRIMARY_SECTION_BACKGROUND = "#F5FBFF"
export const SECONDARY_SECTION_BACKGROUND = "#16386C"

// border colors
export const BORDER_COLOR = "#CCCCCC"

// text colors
export const PRIMARY_TXT_COLOR = "#333333"
export const SECONDARY_TXT_COLOR = "#666666"
export const HEADER_TXT_COLOR = "#183247"
export const NAVLINK_COLOR = "#555555"
export const HIGHLIGHT_TEXT = "#27AE60"

// button colors
export const BUTTON_PRIMARY = "#1976D2"
export const BUTTON_HOVER = "#1E88E5"

// icon colors
export const ICON_COLOR = "#FFF"
export const ICON_SELECTED_BACKGROUND_COLOR = "rgba(255, 255, 255, 0.3)"
export const ICON_BACKGROUND = "#BBDEFB"

//status colors
export const HIGH = "#18D7C7"
export const MEDIUM = "#FED82B"
export const LOW = "#FF6766"
